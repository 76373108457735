.widget-wrapper {
  border: 2px solid var(--light-gray-border);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--brand-superlight-purple);
}

.current-position-widget {
  padding: 1rem 2rem;
}

.doughnut-wrapper {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* cursor: pointer; */
}

/* removes some global styles */
.dashboard-doughnut-wrapper h3,
.dashboard-doughnut-wrapper p {
  padding: 0;
  margin: 0;
}

.widget-wrapper h3 {
  font-size: 1rem;
  word-wrap: normal;
  white-space: wrap;
}

.widget-job-name {
  white-space: wrap;
  @apply line-clamp-2 w-full overflow-hidden;
  line-height: auto;
  font-size: 1rem;
  font-weight: 400;
  color: black;
}

.widget-subtitle {
  @apply min-h-5 line-clamp-1;
  font-size: 0.75rem;
  color: var(--medium-dark-gray);
  padding: 1rem 0rem;
  margin: 0;
}

.empty-state-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 200px;
  font-size: 14px;
  min-height: 140px;
  width: 100%;
  p {
    padding: 0;
    margin: 0;
    font-size: 16px;
  }
}

.rocket-emoji {
  font-size: 30px !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dashboard-doughnut-wrapper > div {
  min-height: 3rem;
}

.current-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}

.dashboard-doughnut-wrapper {
  @apply flex flex-col h-48 justify-between w-40 cursor-pointer;
}
