.dashboard-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @apply w-auto max-w-screen-lg gap-4 m-4;
}
.positions-wrapper {
  display: flex;

  width: 100%;
  flex-wrap: nowrap;
  gap: 1rem;
  height: 100%;
}

.half-size {
  @apply w-auto md:w-[45%] md:grow md:h-full;
}

.two-thirds {
  @apply  w-3/5 grow md:h-full;
}

.one-third {
  width: min-content;
  flex-grow: 1;
  min-height: 100%;
}

@media (max-width: 975px) {

  .dashboard-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .positions-wrapper {
    flex-wrap: wrap;
  }
}
