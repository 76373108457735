.target-positions-widget {
  height: 100%;
}

.target-content-wrapper {
  width:100%;
  display: flex;
  flex-wrap: nowrap; 
  @apply overflow-x-hidden;
}


.slider-arrow {
  font-size: larger;
  z-index: 100 !important;
  display: block !important;
}

/* below for the built in arrows */
.slick-prev:before,
.slick-next:before {
  @apply text-vw-brand-green z-50 hidden pointer-events-none;
}

.slick-prev {
  z-index: 1;
}
.slick-next {
  z-index: 1;
} 

.slick-list {
  height: fit-content !important;
}
.add-position-wrapper {
  width: 300px !important;
  border-right: none !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  margin-left: 1rem;
  flex-grow: 1;

}

.plus-font {
  font-weight: 600;
  font-size: 24px;
  line-height: 60%;
}

.new-target-wrapper {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  align-items: flex-start;
  /* justify-content: center; */
}

.title-target-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.target-autocomplete-wrapper {
  width: fit-content;
  min-width: 140px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  gap: 1rem;
}



