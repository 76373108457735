.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: auto;
}

.upload-box {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 90%;
  height: auto;
  min-height: 10rem;
  background-color: var(--light-gray);
  border-radius: 12px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.uploaded-items {
  padding: 1rem;
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-content: start;
}

.flex {
  display: flex;
}

.gap-2 {
  gap: 2;
}
.gap-1 {
  gap: 1;
}

.gap-4 {
  gap: 4rem;
}
.justify-start {
  justify-content: start;
}
.items-center {
  align-items: center;
}

.file-name {
  font-size: large;
}

.item {
  margin: 5px;
}

.label {
  font-weight: 600;
  text-transform: uppercase;
  font-size: medium;
  color: #56636a;
  user-select: none;
  padding-left: 0.7rem;
}

.errors-container {
  border-radius: 0.5rem;
  height: fit-content;
  width: 90%;
  background-color: rgb(216, 3, 3);
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.3rem;
  p {
    padding-left: 1rem;
    color: white;
    font-size: 1.1rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }
}
.errors-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
