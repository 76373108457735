.sub-menu-wrapper {
  width: 100%;
  z-index: 5;
}

.sub-menu-wrapper .MuiMenu-paper {
  background-color: #f5f5f5 !important;
}

.submenu-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-left: -5px;
  padding-left: 10px;
  padding-right: 10px;
}

/* funky margins allow for the mouse to move from trigger to menu without closing/being glitchy */
.submenu-title {
  height: 30px;
  margin-left: -5px;
  padding-left: 25px;
  width: 100%;
  font-weight: 600;
  font-size: 0.9rem;
}

.submenu-title:after {
  content: '';
  display: block;
  width: 70%;
  border-bottom: 2px solid black;
  margin-bottom: 0;
  margin-top: 10px;
}
