.draggable-list-container {
  width: auto;
  display: inline-block;
  padding: 0 0.625rem;
  text-decoration: none;
  font-weight: bold;
}

.draggable-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: var(--light-gray);
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
}

.adjust-left {
  width: 100%;
  padding-left: 15rem;
}

.icon {
  font-size: 1rem;
  border: none;
  cursor: pointer;
}
.green {
  color: green;
}
.red {
  color: rgb(230, 4, 4);
}
.gray {
  color: var(--dark-gray);
}

.spacer {
  width: 25px;
}
.draggable-row .input {
  width: 100%;
  padding-left: 15rem;
}

.draggable-list-container .header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
}
.draggable-list-container .header-row > div:first-child {
  padding-left: 3rem;
}
.draggable-list-container .header-row > div:nth-child(2) {
  width: 100%;
  padding-left: 14rem;
}

.pl-2 {
  padding-left: 4rem;
}

@media (max-width: 900px) {
  .adjust-left {
    padding-left: 3rem;
  }
  .draggable-list-container .header-row > div:nth-child(2) {
    padding-left: 2rem;
  }

  .pl-2 {
    padding-left: 2rem;
  }
  .draggable-list-container .header-row > div:first-child {
    padding-left: 1rem;
  }
  .draggable-row .input {
    padding-left: 3rem;
  }
}
