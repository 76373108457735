.certification-list-item {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  justify-content: space-between;
}

.certification-list-item-skill-name {
  @apply text-vw-medium-dark-gray m-0 text-sm;
}

.showAllDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  color: var(--dark-gray);
  cursor: pointer;
}

.showAllDiv p {
  margin-right: 4px;
}
