.goals-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1040px;
  gap: 1rem;
  margin: 1rem 1rem;
}

.goals-container > .image-wrapper {
  width: 20rem;
  height: 20rem;
}

.preview-centered-text {
  @apply text-2xl font-bold text-vw-brand-purple;
}
