.im-page {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  label {
    font-weight: 800;
  }
}

.im-left {
  width: 40%;
  min-width: 320px;
}

.im-right {
  width: 60%;
}

.legend-container {
  display: flex;
  flex-direction: column;
  height: 160px;
  gap: 0.4rem;
  padding-left: 1rem;
  padding-right: 0.3rem;
  border-bottom: 4px solid var(--medium-gray);
}

.legend-container-tab-view {
  display: flex;
  flex-direction: column;
  height: 90px;
  gap: 0.4rem;
  padding-left: 1rem;
  padding-right: 0.3rem;
  border-bottom: 4px solid var(--medium-gray);
}

.im-left .im-right {
  display: flex;
  flex-direction: column;
}

.mobile-hidden {
  display: block;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.im-type-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.im-container {
  display: flex;
  width: 100%;
}

.green-button {
  color: white;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 20px;
  border: 2px solid white;
  background-color: var(--brand-green);
  float: right;
}
.green-button:hover {
  cursor: pointer;
}
.people-filters {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--brand-green);
}

.people-filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.return-button {
  display: none;
}
.return-button:hover {
  cursor: pointer;
}

.legend {
  height: 20px;
  width: 20px;
  margin: 5px;
  border-radius: 4px;
}

.flex-row-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flex-row-nowrap p {
  margin-right: 0.5em;
}

.desired-box.legend > div:first-child {
  height: 50%;
  border-right: 5px solid var(--dark-gray);
  border-bottom: 5px solid var(--dark-gray);
}

.desired-box.legend > div:nth-child(2) {
  height: 50%;
  border-right: 5px solid var(--dark-gray);
}

.scores-wrapper,
.skill-on-plan-wrapper {
  margin-top: 1rem;
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: scroll;
}

.loading-wrapper {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.send-survey-button {
  color: var(--brand-purple);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 700;
}

.move-left {
  transform: translateX(-70px);
}
/* left side of IM */
.scores-wrapper {
  height: calc(100vh - 330px);
}

/* right side of IM */
.skill-on-plan-wrapper {
  display: flex;
  flex-direction: column;
  border-left: 4px solid var(--medium-gray);
}

.main-filters-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 160px;
  border-bottom: 4px solid var(--medium-gray);
}

.main-filters-wrapper-tab-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 90px;
  border-bottom: 4px solid var(--medium-gray);
}

.scores-widget {
  @apply rounded-2xl min-h-[6rem] h-fit m-4 flex flex-col;
  border: 1px solid var(--light-gray);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.scores-widget-content {
  @apply items-center flex justify-between pl-4;
}

.scores-widget:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  .color-bar {
    background-color: var(--dark-gray);
  }
  cursor: pointer;
}
.scores-widget > .color-bar {
  transition: background-color 0.3s ease;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 20px;
  background-color: var(--light-gray);
}

.selected.scores-widget {
  border: 1px solid var(--brand-purple);
}

.selected.scores-widget > .color-bar {
  background-color: var(--brand-purple);
}

.skill-on-plan-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: fit-content;
  width: auto;
  overflow-y: scroll;
}

.skill-on-plan-headers-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skill-on-plan-headers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin: 0.5rem;
}

.skill-on-plan-doughnut {
  width: fit-content;
}

.skill-on-plan-doughnut.mobile {
  display: none;
}

.doughnut-wrapper {
  width: 150px;
  /* height: 80px; */
  overflow: hidden;
}

.highcharts-plot-border {
}

.border-b-brand-green {
  border-bottom: 2px solid var(--brand-green);
}

.sort-wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
}

.collapsible-skill-contianer {
  width: 100%;
}
.collapsible-skill-top {
  @apply rounded-md;
  background-color: var(--medium-gray);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.4rem;
  margin: 0.4rem;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.im-skill-name-header {
  @apply font-bold text-sm break-words min-w-[33%] grow;
}

.collapsible-skill-top > *:nth-child(1) {
  justify-self: start;
  text-align: left;
}
.collapsible-skill-top > *:nth-child(2) {
  width: fit-content;
  justify-self: center;
}

.collapsible-skill-labels {
  border-bottom: 2px solid black;
}
.collapsible-skill-labels,
.collapsible-skill-top {
  margin: 0.4rem;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  align-items: center;
}

.collapsible-skill-labels {
  font-weight: 600;
  font-size: 14px;
  width: 100%;
}

.desktop-hide {
  display: none;
}

.bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.im-title {
  display: block;
}

.proficiency-container {
  width: 200px;
  height: fit-content;
  margin: 0.2rem;
  background-color: white;
  padding-bottom: 0.2rem;
}

.add-proficiency {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  overflow-wrap: anywhere;
  padding: 1rem;
}

.slider-wrapper {
  width: auto;
  min-width: 170px;
  margin-left: 0.5rem;
}

.scores-widget-text-container {
  @apply m-0;
  width: 100%;
}

.surveyed-text {
  @apply m-0;

  font-style: italic;
  color: var(--brand-purple);
  font-weight: 700;
  font-size: small;
}

.mobile-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tracking-options-wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 0.5rem;
  padding: 10px;
  width: fit-content;
  max-width: 150px;
}

.proficiency-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
  justify-content: start;
  align-items: flex-start;
}

.edit-proficiency-button-wrapper {
  width: fit-content;
  @apply flex items-center justify-center m-2 pr-2;
  flex-grow: 1;
}

.icon-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
}

.mobile-flex-row {
  display: none !important;
}

@media screen and (max-width: 770px) {
  .bar-wrapper {
    flex-direction: column;
  }

  .mobile-flex-row {
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .mobile-hidden {
    display: none !important;
  }

  .return-button {
    font-size: 1rem;
    display: block;
  }

  .im-right,
  .im-left {
    width: 100%;
  }

  .im-page {
    padding: 1rem;
    padding-left: 0;
    padding-right: 0.5rem;
  }
  .people-filters {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .desktop-hide {
    display: block;
  }
}

@media screen and (max-width: 476px) {
  .add-proficiency {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .collapsible-skill-top {
    grid-template-columns: 1fr;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }




  .collapsible-skill-labels {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .legend-container-tab-view,
  .legend-container,
  .main-filters-wrapper,
  .main-filters-wrapper-tab-view {
    height: fit-content;
    margin-bottom: 10px;
  }

  .icon-wrapper {
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    margin: 0.2rem;
  }

  .tracking-options-wrapper {
    justify-items: center;
    align-items: center;
  }
  .im-page {
    padding: 0rem;
  }

  .skill-on-plan-doughnut.mobile {
    display: block;
  }

  .legend-container {
    padding: 0px;
  }

  .people-filters {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .skill-on-plan-wrapper {
    border-left: none;
  }
}
