.nav-drawer-container {
  @apply flex print:hidden;
}

.nav-drawer > div {
  background-color: var(--light-gray) !important;
}

.nav-bar-logo {
  width: 30px;
  padding: 0.5rem;
  padding-left: 1rem;
  object-fit: contain;
  transition: none !important;
  top: 0;
  left: 0;
  position: absolute;
}

.logo-open {
  width: 200px;
}

.flex-row-between {
  @apply flex flex-row flex-nowrap justify-between items-center w-full;
}

.chevron-right {
  font-size: 10pt;
  color: #c4c4c4;
}

.chevron-white {
  color: white;
}

.top-icons {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 4rem;
  /* z-index: -1; */
}
.nav-bar-icon {
  height: 2rem;
  width: auto;
}

.home-icon {
  height: 1.15rem;
  width: auto;
  padding: 0.4rem;
  color: var(--brand-purple);
}

.icon-label {
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: 10pt;
  color: var(--dark-gray);
  font-family: Montserrat, sans-serif;
}

.logo-icon-button:hover {
  background-color: none !important;
}

.icon-button {
  display: flex !important;
  justify-content: flex-start !important;
  width: 100%;
  border-radius: 0 !important;
  z-index: 5;
}

.icon-button:hover {
  background-color: var(--brand-light-purple) !important;
}

.selected {
  background-color: var(--brand-light-purple) !important;
}

.green-circle {
  @apply text-sm bg-vw-brand-green rounded-full h-10 w-10 aspect-square transition-none text-white flex flex-col justify-center items-center text-center overflow-visible;
}

.user-icon-wrapper {
  @apply py-6 w-full flex flex-row justify-start items-center rounded-none;
  background-color: var(--medium-gray) !important;
  border-radius: 0px !important;
}

.current-user-container {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  font-weight: 700;
  font-size: 10pt;
}

.font-regular {
  font-weight: 400;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 7;
  background-color: black;
  opacity: 0.4;
}
