.skill-by-category-widget {
  @apply flex flex-col items-start justify-start;
}

.by-category-container {
  @apply w-full  h-max min-w-[275px] max-w-[600px] overflow-visible;
}

.skills-by-category-legend {
  @apply w-full;
}
