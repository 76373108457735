.skills-summary-container {
  @apply flex w-auto max-w-screen-lg gap-4 m-4;
}

.skills-summary-container > .left-column {
  @apply w-[55%] flex flex-col gap-4;
}

.skills-summary-container > .right-column {
  @apply w-[45%] flex flex-col gap-4;
}

@media (max-width: 1100px) {
  .skills-summary-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .skills-summary-container > .left-column {
    @apply w-full;
  }

  .skills-summary-container > .right-column {
    @apply w-full;
  }
}
