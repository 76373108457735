.drag-drop-file {
  width: 100%;
  height: 100%;
  .drag-drop-file-area {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1.2rem;
    color: #2e2e2e;
    border-radius: 12px;
    font-weight: 500;
    .area-icon {
      font-size: 64px;
      margin-top: 20px;
    }
  }
}

.dragging {
  background-color: rgb(153, 153, 153) !important;
}

.error-text {
  color: rgb(216, 3, 3);
  font-size: large;
  padding-bottom: 0.5rem;
}
