.edit-proficiency-top {
  @apply px-4 py-2 min-h-10;
  background-color: var(--light-gray);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: fit-content;
  column-gap: 10px;
}

.edit-proficiency-top-wrapper {
  border: 2px solid var(--light-gray);
  @apply border-2 rounded-md;
  margin-bottom: 8px;
}

@media screen and (max-width: 476px) {
  .edit-proficiency-top {
    grid-template-columns: 1fr;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
