.update-skills-widget {
  @apply flex flex-col items-start justify-start;
  p {
    @apply pb-4;
  }
}

.skills-container {
  @apply flex flex-col items-center justify-center gap-4 overflow-hidden;
}
