.documents-table {
  width: 100%;
  border-collapse: collapse;
  font: larger;
}

.header-row,
.row {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.header-row {
  font-weight: 600;
  font-size: small;
  color: #56636a;
  user-select: none;
  background-color: transparent;
}

.row {
  background-color: var(--light-gray);
  margin-bottom: 1rem;
  border-radius: 0.6rem;
}

.cell {
  flex: 1;
  padding: 8px;
  text-align: center;
  gap: 1;
  align-items: center;
  justify-content: center;
  div {
    padding-left: 0.5rem;
  }
}

.options-cell {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.icon {
  font-size: 1rem;
  border: none;
  cursor: pointer;
  line-height: 1;
}

.red {
  color: rgb(230, 4, 4);
}
.gray {
  color: var(--dark-gary);
}
.light-gray {
  color: #cccccc;
}
.green {
  color: green;
}

.purple {
  color: #7860dc;
}

.flex {
  display: flex;
}

.arrow-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

/* small */
@media (min-width: 640px) {
}
/* medium */
@media (min-width: 768px) {
}
