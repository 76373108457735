.bold-widget-title {
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: none;
  font-family: sans-serif;
  margin: 0 0;
  color: var(--dark-gray);
}

.subtitle-divider {
  border-bottom-color: var(--medium-gray);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.in-widget-loading {
  margin: 0 auto;
  text-align: center;
  margin-left: 45%;
}
