.incomplete-surveys-widget.widget-wrapper {
  background-color: var(--brand-green);
  border: none;
  color: white;
  flex-grow: 1;
  height: fit-content;
  min-height: 100%;
}

.incomplete-surveys-widget.as-manager {
  background-color: var(--brand-superlight-purple);
  border: 2px solid var(--light-gray-border);
  color: black;
}

.widget-wrapper h2,
.widget-wrapper p {
  margin: 0;
  padding: 0;
  word-wrap: normal;
  white-space: wrap;
}

.incomplete-empty-wrapper {
  margin-top: 2rem;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.incomplete-empty-wrapper p {
  font-size: 1rem;
  font-weight: 500;
}
.incomplete-empty-wrapper .image-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.surveys-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.surveys-wrapper > p {
  font-size: 0.75rem;
  font-weight: 400;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.current-surveys {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.incomplete-survey {
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.incomplete-survey button {
  background-color: var(--brand-light-green);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: var(--brand-green);
  font-size: 1rem;
}
.as-manager .incomplete-survey button {
  background-color: RGBA(var(--brand-purple-rgb), 0.12) !important;
  color: var(--brand-purple);
}

.incomplete-plan-name {
  color: var(--brand-green);
  font-size: 1rem !important;
  font-weight: 600 !important;
  white-space: wrap;
}
.as-manager .incomplete-plan-name {
  color: var(--brand-purple);
}

.incomplete-surveys-widget.as-manager .bold-widget-title {
  color: var(--dark-gray);
}
.incomplete-surveys-widget .bold-widget-title {
  color: white;
}
.incomplete-plan-category {
  color: black;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}
