.custom-tab-common-styling {
  font-size: 0.75rem !important;
}
.MuiTypography-root {
  font-size: 0.75rem !important;
}

@media (max-width: 800px) {
  .custom-tab-common-styling > div {
    padding: 0 !important;
  }
}
