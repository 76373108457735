.skill-on-plan-wrapper2 {
  max-height: calc(100vh - 150px);
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.skill-on-plan-main-content2 {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.editable-skills-warapper {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
}

.as-manager-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.drop-down-wrapper {
  width: 190px !important;
}

.collapsible-skill-contianer {
  width: 100%;
  margin-bottom: 0.5rem;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  width: 99%;
  border-top: none;
}


.collapsible-skill-top {
  margin: 0;
  width: 94%;
  background-color: var(--light-gray);
}

.im-right-loading{
  min-height: calc(100vh - 200px);
  min-width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}