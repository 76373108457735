.tab-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.defaults-container {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.default-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.new-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5rem;
}
.page-divider {
  width: 100%;
  border-bottom: 4px solid #eee;
}

.name{
  display: inline-block;
  padding: 0 0.625rem;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 900px) {
  .defaults-container {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .default-wrapper {
    flex-direction: column;
  }

  .new-wrapper {
    flex-direction: column;
    gap: 2rem;
  }
}
