.button {
  border-radius: 1.5rem;
  background-color: #44a896;
  color: white;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}

.white {
  color: #44a896;
  background-color: white;
  border: 1px solid #44a896;
}

.sm {
  padding: 0.5rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  font-size: small;
}

.md {
  padding: 0.6rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  font-size: medium;
}

.lg {
  padding: 0.7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: larger;
}

.button:hover {
  background-color: #555555;
}

.button.disabled {
  border: 1px solid #555555;
  background-color: #cccccc;
  color: #555555;
  cursor: not-allowed;
}

.button.disabled:hover {
  background-color: #555555;
}

.white:hover {
  background-color: #44a896;
  color: white;
}
